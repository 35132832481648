
const loadTemplateOptionsEventListeners = () => {
  function removeVariantRow(removeButton) {
    const variantDiv = removeButton.parentElement;
    variantDiv.remove();
  };

  function addListeners() {
    const variantDiv = document.getElementById("add-variant");

    if (!variantDiv) {
      return; 
    }

    variantDiv.addEventListener("click", addVariantHandler);
    document.querySelectorAll("button.button.remove-button").forEach((button) => {
      button.addEventListener("click", function () {
        removeVariantRow(button);
      });
    });

    const sectionSelect = document.getElementById("section-select");
    const sectionToHide = document.getElementById("variants-field");

    sectionSelect.addEventListener("change", function() {
      if (sectionSelect.value === "boolean") {
        document.querySelectorAll(".is-flex.is-flex-direction-row").forEach((variantDiv) => {
          variantsContainer.removeChild(variantDiv)
        });

        sectionToHide.style.display = "none";
      } else {
        sectionToHide.style.display = "block";
      }
    });
  };

  function addVariantHandler() {
    const variantsContainer = document.getElementById("variants-container");
    
    const labelInput = document.createElement("input");
    labelInput.type = "input";
    labelInput.className = "input mr-4 mb-4"
    labelInput.required = true

    labelInput.name = "template_option[variants][][label]";
    labelInput.placeholder = "Label";
  
    const valueInput = document.createElement("input");
    valueInput.type = "input";
    valueInput.className = "input mr-4"
    labelInput.required = true;

    valueInput.name = "template_option[variants][][value]";
    valueInput.placeholder = "Value";
  
    const removeButton = document.createElement("button");
    removeButton.type = "button";
    removeButton.className = "button"
    removeButton.innerText = "Remove Variant";
  
    removeButton.addEventListener("click", function() {
      variantsContainer.removeChild(variantDiv);
    });
  
    const variantDiv = document.createElement("div");
    variantDiv.className = "field is-flex is-flex-direction-row";
    variantDiv.appendChild(labelInput);
    variantDiv.appendChild(valueInput);
    variantDiv.appendChild(removeButton);
  
    variantsContainer.appendChild(variantDiv);
  };
  
  // Add event listener for turbolinks:load event
  document.addEventListener("turbolinks:load", function() {
    addListeners();
  });
}

export default loadTemplateOptionsEventListeners;
